<template>
  <div>
    <page-toolbar :title="rmpData.header.no_">
      <template slot="action">
        <v-btn text @click="saveData()" :disabled="!editPermission">Save</v-btn>
      </template>
    </page-toolbar>
    <v-container>
      <page-title :title="rmpData.header.no_">
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mx-1" @click="saveData()" :disabled="!editPermission" color="primary">Save</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col md="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :disabled="!editPermission">
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.no_" placeholder="No." label="No." readonly filled outlined></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-dialog ref="refModal1" v-model="modal1" :return-value.sync="rmpData.header.document_date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense v-model="rmpData.header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="rmpData.header.document_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.refModal1.save(rmpData.header.document_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="rmpData.header.campaign_no_" placeholder="Campaign No." label="Campaign No." readonly outlined filled></v-text-field>
                <v-text-field dense v-model="rmpData.header.campaign_description" placeholder="Campaign Description" label="Campaign Description" readonly outlined filled></v-text-field>
                <!-- <v-text-field dense v-model="rmpData.header.status" placeholder="Status" label="Status" readonly outlined filled></v-text-field> -->
                <!-- <v-select dense v-model="rmpData.header.status" :items="['open', 'released']" label="Status" outlined></v-select> -->
              </v-col>
              <v-col cols="12" md="6">
                <v-select dense outlined single-line return-object label="Customer" placeholder="Customer" item-text="customer_no_" item-value="customer_no_" v-model="selectedCustomer" :items="customers"></v-select>
                <v-text-field dense outlined single-line readonly filled label="Customer Name" placeholder="Customer Name" v-model="rmpData.header.customer_name"></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>

          <!-- Items -->
          <card-expansion title="Items" class="mb-3" :disabled="!editPermission">
            <template slot="action">
              <v-btn icon @click="addItem()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-list three-line v-if="isMobile">
              <template v-for="(item, index) in rmpData.detail">
                <v-divider v-if="index > 0" :key="index"></v-divider>

                <v-list-item :key="item.item_no_">
                  <ImageItem :src="resourceUrl(item.item_image)" class="mx-auto">
                    <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                  </ImageItem>

                  <v-list-item-content class="pl-2">
                    <v-list-item-title v-html="item.item_no_" @click="editItem(item)"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.item_description"></v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span>RM: {{ item.rm_criteria }}</span> |
                      <span>Qty: {{ numberFormat(item.quantity, 2) }}</span> |
                      <span>W: {{ numberFormat(item.weight, 2) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon @click="editItem(item)" style="cursor:pointer">
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon @click="deleteItem(item)" style="cursor:pointer">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
            <v-row v-if="!isMobile">
              <v-col>
                <div class="div-items">

                  <v-data-table :headers="rmpDetailColumns" :items="rmpData.detail" :hide-default-header="isMobile">
                    <template v-slot:[`item.item_image`]="{ item }">
                      <ImageItem :src="item.item_image" class="mx-auto">
                        <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                      </ImageItem>
                    </template>
                    <template v-slot:[`item.item_description`]="{ item }">
                      <a href="javascript:;" @click="editItem(item)">{{ item.item_description }}</a>
                    </template>
                    <template v-slot:[`item.weight`]="{ item }">
                      <span>{{ numberFormat(item.weight, 2) }}</span>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <span>{{ numberFormat(item.quantity, 2) }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)" color="primary">mdi-pencil</v-icon>
                      <v-icon small @click="deleteItem(item)" color="error">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
        <v-col md="3">
          <!-- Loading & Shipment -->
          <card-expansion title="Loading & Shipment" class="mb-3">
            <v-card color="transparent" elevation="0" :disabled="!editPermission" class="pa-0">
              <v-text-field dense v-model="rmpData.header.nopol" placeholder="Nomor Polisi" label="Nomor Polisi" outlined></v-text-field>
              <v-text-field @click="showDtp(rmpData.header.loading_date_start, 'loading_date_start')" dense readonly v-model="rmpData.header.loading_date_start" placeholder="Start Loading" label="Start Loading" outlined></v-text-field>
              <v-text-field @click="showDtp(rmpData.header.loading_date_end, 'loading_date_end')" dense readonly v-model="rmpData.header.loading_date_end" placeholder="End Loading" label="End Loading" outlined></v-text-field>
              <v-text-field @click="showDtp(rmpData.header.est_shipment_date_start, 'est_shipment_date_start')" dense readonly v-model="rmpData.header.est_shipment_date_start" placeholder="Estimate Shpt Date Start" label="Estimate Shpt Date Start" outlined></v-text-field>
              <v-text-field @click="showDtp(rmpData.header.est_shipment_date_end, 'est_shipment_date_end')" dense readonly v-model="rmpData.header.est_shipment_date_end" placeholder="Estimate Shpt Date End" label="Estimate Shpt Date End" outlined></v-text-field>
            </v-card>
          </card-expansion>

          <!-- Attachments -->
          <card-expansion title="Attachments" class="mb-3">
            <v-card color="transparent" elevation="0" :disabled="!editPermission">
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 1</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment1 != 'undefined' && rmpData.header.attachment1 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment1)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment1 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn icon color="primary" @click="uploadAttachment(1)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteAttachment(1)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 2</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment2 != 'undefined' && rmpData.header.attachment2 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment2)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment2 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn icon color="primary" @click="uploadAttachment(2)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteAttachment(2)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 3</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment3 != 'undefined' && rmpData.header.attachment3 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment3)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment3 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn icon color="primary" @click="uploadAttachment(3)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteAttachment(3)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item two-line dense class="px-0">
                <v-list-item-content>
                  <v-list-item-title>Attachment 4</v-list-item-title>
                  <v-list-item-subtitle v-if="typeof rmpData.header.attachment4 != 'undefined' && rmpData.header.attachment4 != ''">
                    <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment4)">
                      <v-icon>mdi-link</v-icon>
                      {{ rmpData.header.attachment4 }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    <v-btn icon color="primary" @click="uploadAttachment(4)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteAttachment(4)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </card-expansion>
        </v-col>
      </v-row>


      <div class="d-flex flex-row flex-row-reverse">
        <v-btn class="mx-1" @click="saveData()" :disabled="!editPermission" color="primary">Save</v-btn>
        <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
      </div>

      <!-- Edit Item -->
      <v-dialog v-model="tmpEdit.dialog" max-width="300">
        <v-card>
          <v-card-title dense>Edit Item</v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-select dense v-model="tmpEdit.data.rm_criteria" :items="$store.state.helper.listRmCriteria" label="RM Criteria" outlined></v-select>
            <v-text-field dense v-model="tmpEdit.data.quantity" type="number" label="Quantity" placeholder="Quantity" outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click.stop="tmpEdit.dialog = !tmpEdit.dialog">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveEditItem()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Item -->
      <v-dialog v-model="tmpDelete.dialog" max-width="300">
        <v-card dense>
          <v-card-title dense>Delete Item</v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <p>
              Delete
              <b>"{{ this.tmpDelete.data.item_description }}"</b>?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn @click.stop="tmpDelete.dialog = !tmpDelete.dialog">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="confirmDeleteItem()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Item -->
      <v-dialog v-model="tmpAdd.dialog" max-width="500">
        <v-form ref="refTmpAddForm" v-model="tmpAdd.valid" lazy-validation>
          <v-card :loading="tmpAdd.loading" :disabled="tmpAdd.loading">
            <v-card-title>{{ tmpAdd.title }}</v-card-title>
            <v-divider class="mb-4"></v-divider>
            <v-card-text>
              <v-select require :rules="[v => (v !== 'undefined') || 'Item is required']" v-model="tmpAdd.selectedItem" :items="binContents" item-text="item_description" item-value="item_no_" return-object label="Select item" dense outlined></v-select>

              <v-select require :rules="[v => !!v || 'RM Criteria is required']" dense v-model="tmpAdd.selectedRmCriteria" :items="$store.state.helper.listRmCriteria" label="RM Criteria" outlined></v-select>

              <v-text-field required :rules="[v => !!v || 'Quantity is required']" v-model="tmpAdd.quantity" type="number" label="Quantity" placeholder="Quantity" dense outlined></v-text-field>

              <input type="file" style="display: none" name="filetmp" id="filetmp" ref="refItemImage" accept="image/*" v-on:change="handleFileTmp()" />

              <span>Image</span>
              <div class="d-flex justify-center my-3" style="max-height:260px">
                <v-img :src="resourceUrl(tmpAdd.itemImg)" sizes="300" class="grey darken-2" contain style="border-radius:12px">
                  <div class="d-flex flex-row justify-end pb-4 my-4 fill-height">
                    <v-btn :disabled="tmpAdd.loading2" small rounded class="mb-1 mx-3" color="primary" @click="$refs.refItemImage.click()" style="max-width:120px">
                      <v-icon>mdi-upload</v-icon>Upload
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="tmpAdd.loading2" small rounded class="mb-1 mx-3" color="error" @click="tmpAdd.itemImg = ''" style="max-width:120px">
                      <v-icon>mdi-close</v-icon>Clear
                    </v-btn>
                  </div>

                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular v-if="tmpAdd.loading2" indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click.stop="tmpAdd.dialog = !tmpAdd.dialog">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="saveAddItem()" :disabled="!tmpAdd.valid">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />

      <v-dialog v-model="viewImgItem_dialog" transition="dialog-bottom-transition" max-width="400">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Image Preview</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="viewImgItem_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-img :src="resourceUrl(viewImgItem_src)" contain max-height="320"></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="viewImgItem_dialog = !viewImgItem_dialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="dtpDialog" ref="dtpDialog" width="400">
        <v-card>
          <v-card-title class="darkblue d-flex justify-center">
            <span>{{ dtpText }}</span>
          </v-card-title>
          <v-date-picker no-title v-model="dtpDate" full-width class="mt-4"></v-date-picker>
          <v-card-text>
            <v-row>
              <v-col><v-select v-model="dtpH" :items="dtpListHours" label="Hours" dense></v-select></v-col>
              <v-col><v-select v-model="dtpI" :items="dtpListMinutes" label="Minute" dense></v-select></v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDtp()">Cancel</v-btn>
            <v-btn text @click="setDtp()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ImageItem from '../rmp/ImageItem.vue'
export default {
  components: {
    ImageItem,
  },

  data() {
    return {
      modal1: null,
      modal2: null,
      modal3: null,
      modal4: null,
      modal5: null,
      modal6: null,
      modal7: null,
      modal8: null,
      modal9: null,
      modal10: null,

      rmpData: {
        header: {},
        detail: [],
      },
      rmpDetailColumns: [
        { text: "Image", value: "item_image", sortable: false },
        { text: "No.", value: "item_no_" },
        { text: "Description", value: "item_description" },
        { text: "RM Criteria", value: "rm_criteria" },
        { text: "UoM", value: "uom" },
        { text: "Weight", value: "weight", align: "end" },
        { text: "Quantity", value: "quantity", align: "end" },
        { text: "Action", value: "actions", sortable: false },
      ],

      viewImgItem_dialog: null,
      viewImgItem_src: null,

      tmpEdit: {
        dialog: false,
        data: {},
      },
      tmpDelete: {
        dialog: false,
        data: {},
      },
      items: [],
      fileupload: null,
      n: null,

      customers: [],
      selectedCustomer: {
        customer_no_: "",
        customer_name: "",
      },

      binContents: [],
      tmpAdd: {
        title: "Add New Item",
        loading: null,
        loading2: false,
        dialog: false,
        itemImg: null,
        selectedItem: {},
        selectedRmCriteria: '',
        quantity: null,
        valid: null,
      },


      dtpDialog: null,
      dtpDate: null,
      dtpRefVal: null,
      dtpH: null,
      dtpI: null,
      dtpListHours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',],
      dtpListMinutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55',],
    };
  },

  computed: {
    editPermission() {
      if (typeof this.rmpData.header.status === "undefined") return false;
      if (this.rmpData.header.status == "open") return true;
      return false;
    },

    dtpText() {
      return this.dtpDate + ' ' + this.dtpH + ':' + this.dtpI + ':00'
    }
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line no-unused-vars
    selectedCustomer(n, o) {
      if (typeof n !== "undefined") {
        this.rmpData.header.customer_no_ = n.customer_no_;
        this.rmpData.header.customer_name = n.customer_name;
      }
    },
  },
  methods: {
    async getDetail(refresh) {
      if (refresh == true) this.AxiosStorageRemove("GET", "rmp/data-detail");

      var id = this.$route.query.id;
      this.showLoadingOverlay(true);
      var params = {
        id: id,
      };
      var config = {
        params: params,
        cacheConfig: true,
      };
      await this.$axios
        .get("rmp/data-detail", config)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.rmpData = resData.data;
            // console.log(resData.data.header.campaign_no_);

            this.selectedCustomer.customer_no_ =
              resData.data.header.customer_no_;
            this.selectedCustomer.customer_name =
              resData.data.header.customer_name;

            // console.log(resData.data.detail);

            this.getCustomers(resData.data.header.campaign_no_);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async getCustomers(campaign_no_, refresh) {
      if (refresh == true) this.AxiosStorageRemove("GET", "rmp/customers");
      var params = {
        campaign_no_: campaign_no_,
      };
      await this.$axios
        .get("rmp/customers", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.customers = resData.data.customers;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },

    async getBinContent(refresh) {
      if (refresh === true) this.AxiosStorageRemove("GET", "rmp/bin-content");

      this.tmpAdd.loading = true;

      this.items = [];
      var params = {
        bin_code: this.rmpData.header.campaign_no_,
        location_code: this.rmpData.header.customer_no_,
      };

      var config = { params: params, cacheConfig: true };
      await this.$axios
        .get("rmp/bin-content", config)
        .then((res) => {
          this.tmpAdd.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.binContents = resData.data.items;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.tmpAdd.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    // Edit Item
    editItem(item) {
      this.tmpEdit.data = item;
      this.tmpEdit.dialog = true;
    },
    saveEditItem() {
      for (var i = 0; i < this.rmpData.detail.length; i++) {
        if (this.tmpEdit.data.item_no_ == this.rmpData.detail[i].item_no_) {
          this.rmpData.detail[i].quantity = this.tmpEdit.data.quantity;
        }
      }
      this.tmpEdit.data = {};
      this.tmpEdit.dialog = false;
      this.showAlert("success", "Item saved");
    },

    // Delete Item
    deleteItem(item) {
      this.tmpDelete.dialog = true;
      this.tmpDelete.data = item;
    },
    confirmDeleteItem() {
      var tmp = [];
      for (var i = 0; i < this.rmpData.detail.length; i++) {
        if (this.tmpDelete.data.item_no_ != this.rmpData.detail[i].item_no_) {
          tmp.push(this.rmpData.detail[i]);
        }
      }

      this.rmpData.detail = tmp;
      this.tmpDelete.data = {};
      this.tmpDelete.dialog = false;
      this.showAlert("success", "Item Deleted");
    },

    // Add Item
    addItem() {
      if (this.rmpData.header.customer_no_ == "") {
        this.showAlert("warning", "Please select customer!");
        return;
      }

      this.tmpAdd.dialog = true;
      this.tmpAdd.selectedItem = null;
      this.tmpAdd.selectedRmCriteria = null;
      this.tmpAdd.valid = null;
      this.tmpAdd.itemImg = null;
      this.tmpAdd.quantity = null;
      this.getBinContent();
    },
    async handleFileTmp() {
      this.tmpAdd.loading2 = true;
      const file = this.$refs.refItemImage.files[0];
      let formData = new FormData();
      formData.append("image", file);
      await this.$axios
        .post("rmp/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.tmpAdd.loading2 = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.tmpAdd.itemImg = resData.data.src;
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.tmpAdd.loading2 = false;
          console.log("FAILURE!!");
        });
    },
    saveAddItem() {
      this.tmpAdd.loading = true;


      var tmp = [];
      var exists = false
      for (var i = 0; i < this.rmpData.detail.length; i++) {
        // console.log(this.rmpData.detail[i]);
        // console.log(this.tmpAdd.selectedItem);
        if (
          (this.rmpData.detail[i].item_no_ == this.tmpAdd.selectedItem.item_no_)
          && (this.rmpData.detail[i].rm_criteria == this.tmpAdd.selectedRmCriteria)
        ) {
          exists = true
          var _item = {
            rmp_header_id: this.rmpData.header.id,
            item_no_: this.tmpAdd.selectedItem.item_no_,
            item_description: this.tmpAdd.selectedItem.item_description,
            item_image: this.tmpAdd.itemImg,
            uom: this.tmpAdd.selectedItem.uom,
            weight: this.tmpAdd.selectedItem.weight,
            rm_criteria: this.tmpAdd.selectedRmCriteria,
            document_no_: this.rmpData.header.document_no_,
            quantity: this.tmpAdd.quantity,
          };

          if (confirm('Item is already exists!, \nReplace existing item?')) {
            tmp.push(_item);
          } else {
            tmp.push(this.rmpData.detail[i]);
          }

        } else {
          tmp.push(this.rmpData.detail[i]);
        }
      }

      if (!exists) {
        var _item2 = {
          rmp_header_id: this.rmpData.header.id,
          item_no_: this.tmpAdd.selectedItem.item_no_,
          item_description: this.tmpAdd.selectedItem.item_description,
          item_image: this.tmpAdd.itemImg,
          uom: this.tmpAdd.selectedItem.uom,
          weight: this.tmpAdd.selectedItem.weight,
          rm_criteria: this.tmpAdd.selectedRmCriteria,
          document_no_: this.rmpData.header.document_no_,
          quantity: this.tmpAdd.quantity,
        };
        tmp.push(_item2);
      }

      this.rmpData.detail = tmp;

      // var item = {
      //   rmp_header_id: this.rmpData.header.id,
      //   item_no_: this.tmpAdd.selectedItem.item_no_,
      //   item_description: this.tmpAdd.selectedItem.item_description,
      //   item_image: this.tmpAdd.itemImg,
      //   uom: this.tmpAdd.selectedItem.uom,
      //   weight: this.tmpAdd.selectedItem.weight,
      //   rm_criteria: this.tmpAdd.selectedRmCriteria,
      //   document_no_: this.rmpData.header.document_no_,
      //   quantity: this.tmpAdd.quantity,
      // };

      // this.rmpData.detail.push(item);

      // console.log(this.rmpData.detail);

      this.tmpAdd.loading = false;
      this.tmpAdd.dialog = false;
    },

    // eslint-disable-next-line no-unused-vars
    uploadAttachment(n) {
      this.$refs.reffileupload.click();
      this.n = n;
    },

    deleteAttachment(n) {
      this.n = n;

      if (this.n == 1) {
        this.rmpData.header.attachment1 = "";
      } else if (this.n == 2) {
        this.rmpData.header.attachment2 = "";
      } else if (this.n == 3) {
        this.rmpData.header.attachment3 = "";
      } else if (this.n == 4) {
        this.rmpData.header.attachment4 = "";
      }

      this.n = null;
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0];

      var id = this.$route.params.id;

      if (!confirm("Upload File?")) return;

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      formData.append("id", id);
      formData.append("n", this.n);
      this.$axios
        .post("rmp/upload-attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            if (this.n == 1) {
              this.rmpData.header.attachment1 = resData.data.src;
            } else if (this.n == 2) {
              this.rmpData.header.attachment2 = resData.data.src;
            } else if (this.n == 3) {
              this.rmpData.header.attachment3 = resData.data.src;
            } else if (this.n == 4) {
              this.rmpData.header.attachment4 = resData.data.src;
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          console.log("FAILURE!!");
        });
    },

    saveData() {
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("header", JSON.stringify(this.rmpData.header));
      formData.append("detail", JSON.stringify(this.rmpData.detail));
      this.$axios
        .post("rmp/update", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.getDetail(true);

            this.$router.go(-1)
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    setDMYHI(val) {
      var _date = new Date(val)

      return _date.toISOString().split('T')[0] + ' ' + _date.getHours().padStart(2, '0') + ':' + _date.getMinutes().padStart(2, '0')
    },

    showDtp(val, ref) {
      console.log(val);

      if ((val == '') || (val == null)) val = new Date().toString();


      var _date = new Date(val)

      this.dtpDate = _date.toISOString().split('T')[0]
      this.dtpRefVal = ref
      this.dtpH = String(_date.getHours()).padStart(2, '0');
      this.dtpI = String(_date.getMinutes()).padStart(2, '0');


      this.dtpDialog = true;
    },
    setDtp() {
      this.dtpDialog = false;

      if (this.dtpRefVal == 'loading_date_start')
        this.rmpData.header.loading_date_start = this.dtpDate + ' ' + this.dtpH + ':' + this.dtpI + ':00'
      if (this.dtpRefVal == 'loading_date_end')
        this.rmpData.header.loading_date_end = this.dtpDate + ' ' + this.dtpH + ':' + this.dtpI + ':00'
      if (this.dtpRefVal == 'est_shipment_date_start')
        this.rmpData.header.est_shipment_date_start = this.dtpDate + ' ' + this.dtpH + ':' + this.dtpI + ':00'
      if (this.dtpRefVal == 'est_shipment_date_end')
        this.rmpData.header.est_shipment_date_end = this.dtpDate + ' ' + this.dtpH + ':' + this.dtpI + ':00'

      this.dtpDate = null
      this.dtpRefVal = null
      this.dtpH = null
      this.dtpI = null
    },
    cancelDtp() {
      this.dtpDialog = false;
      this.dtpDate = null
      this.dtpRefVal = null
      this.dtpH = null
      this.dtpI = null
    }
  },

  mounted() {
    this.requiredLogin();
    this.modulePermission('rmp', 'modify', true)
    this.getDetail();
  },
};
</script>

<style lang="scss">
.div-items .v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
  height: initial;
  min-height: 22px !important;
  border-top: none;
}

.div-items .v-data-table__mobile-table-row {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.darkblue {
  background-color: darkblue;
  color: #fff;
}
</style>